<!--  -->
<template>
  <div class="renzheg">
    <div class="tips card">
      <div class="tips-top">
        <p class="id-card">身份认证</p>
      </div>
      <div class="id-title">
        <p>
          一、根据法律法规要求，游戏玩家需先进行实名认证，方可进行相关交易。
        </p>
        <p>
          二、为确保您顺畅的租号体验，请尽快完成下方的实名认证，爱租号平台将对您的信息严格保密。
        </p>
        <p>
          三、如您的实名认证信息填报有误，<span>您有且只有一次提交修改申请的机会</span>，如您如实填报个人信息，确保您所填写的个人信息真实有效，
        </p>
      </div>
      <div class="tips-top">
        <p class="id-card">请完善您的身份证信息</p>
      </div>

      <div class="err">
        <p  v-show="isOk">
        身份认证失败!,请重新认证
        </p>
      </div>

      <div class="id-from">
        <div class="id-name">
          <img src="../../assets/order/xh.png" alt="" />
          <span>真实姓名：</span>
          <input type="text" v-model.trim="user_name" :disabled="disabled"/>
          <img class="id-img" src="../../assets/order/yzcg.png" alt="" />
        </div>
        <div class="id-p">
          注意：该姓名需和绑定支付宝姓名一致，否则无法提现成功！
        </div>
        <div class="id-name">
          <img src="../../assets/order/xh.png" alt=""/>
          <span>居民身份证号码：</span>
          <input type="text" v-model.trim="id_card" :disabled="disabled"/>
          <img class="id-img" src="../../assets/order/yzcg.png" alt="" />
        </div>
        <div class="id-txt">
          <span>*</span>
          <p>实名认证完成后，若您的身份未满18岁，仍然禁止发布账号、下单操作</p>
        </div>
        <div class="id-txt con">
          <span>*</span>
          <p>身份证信息只能提交一次，不能随意修改，请慎重填写。</p>
        </div>
      </div>
      <div class="id-btn">
        <el-button type="primary" round @click="submite" :loading="loading" :disabled="disabled" >{{message}}</el-button>
      </div>
      <div class="id-bottom">
        如有疑问，请点击<span @click="$router.push({name:'center'})">帮助中心</span>了解详情！
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  name: "renzheg",
  data() {
    return {
      isOk: false,
      loading: false,
      disabled: false,
      user_name:'',
      id_card:'',
      message: '确定提交'
    };
  },
  created() {
    this.getData()
  },
  methods: {
    submite() {
      let user_id = this.$store.state.user_id
      var that = this;
      if(!that.user_name||that.user_name.length<2){
        that.$message.warning('请输入姓名!');
        return;
      }
      if(!that.id_card||that.id_card.length<18){
        that.$message.warning('请输入正确的身份证号码!');
        return;
      }
      that.$post('api/user/auth',{user_name:that.user_name,id_card:that.id_card,user_id}).then(res => {
        if(res.code==0){
         that.$message.warning('提交成功');
         that.user_name = that.eName(that.user_name)
         that.id_card = that.eId(that.id_card)
         that.loading = true
         that.message = '审核中'
         that.disabled = true
         that.isOk = false
        }else{
          that.$message.error(res.message);
        }
      })
    },
   eName(str) {
    if (null != str && str != undefined) {
      if (str.length <= 3) {
        return "*" + str.substring(1, str.length);
      } else if (str.length > 3 && str.length <= 6) {
        return "**" + str.substring(2, str.length);
      } else if (str.length > 6) {
        return str.substring(0, 2) + "****" + str.substring(6, str.length)
      }
    } else {
      return "";
    }
  },
  eId(str) {
    if (null != str && str != undefined) {
      var pat = /(\d{4})\d*(\d{4})/;
      return str.replace(pat, '$1***********$2');
    } else {
      return "";
    }
  },
  getData() {
    var that = this
    that.$get('api/user/authinfo',{}).then((res)=> {
      console.log(res);
      if(res.code == 0&&res.ret != null) {
        if(res.ret.status == 1){
          that.user_name = that.eName(res.ret.uname)
          that.id_card = that.eId(res.ret.id_card)
          that.disabled = true
          that.message = '认证成功'
        }else if(res.ret.status == 2){
          that.isOk = true
        }
      }
    })
  }

  }
};
</script>

<style lang='scss' scoped >
.renzheg {
  width: 100%;
  height: 780px;
  .tips {
    width: 100%;
    height: 100%;
    padding: 0 38px;
    .tips-top {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      margin-top: 28px;
      p {
        position: relative;
        padding-left: 13px;
        &::before {
          content: "";
          position: absolute;
          top: 4px;
          left: 0px;
          width: 4px;
          height: 17px;
          background: #3c7efe;
          border-radius: 2px;
        }
      }
    }
    .err{
        
      text-align: center;
      height:100px;
      line-height:150px;
      p{
          font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #fc3737;
      }
   
    }
    .id-title {
      height: 140px;
      background: #f6f7fa;
      border-radius: 4px;
      padding: 34px 18px;
      font-size: 12px;
      font-weight: bold;
      color: #000000;
      margin-top: 32px;
      p {
        height: 25px;
      }
      span {
        color: #fc3737;
      }
    }
    .id-from {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;
      .id-name {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 54%;
        img {
          width: 6px;
          height: 6px;
        }
        .id-img {
          width: 14px;
          height: 15px;
          margin-left: 15px;
        }
        input {
          width: 300px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #e1e4eb;
          border-radius: 4px;
          outline: none;
          text-indent: 1em;
        }
        span {
          font-size: 14px;
          font-weight: bold;
          color: #000000;
          margin-left: 5px;
        }
      }
      .id-p {
        font-size: 12px;
        color: #426cbe;
        margin: 15px 0;
        width:54%;
        text-align: right;
      }
      .id-txt {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 67%;
        p {
          font-size: 12px;
          font-weight: 400;
          color: #a2a6b8;
        }
        span {
          margin-right: 8px;
          font-size: 16px;
          color: #a2a6b8;
          padding-top: 5px;
        }
      }
      .con {
        padding-right: 73px;
      }
    }
    .id-btn {
      margin: 45px 0 30px;
      display: flex;
      justify-content: center;
    }
    .id-bottom {
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #b4bdd6;
      text-align: right;
      span {
        font-size: 13px;
        color: #3c7efe;
        cursor: pointer;
      }
    }
  }
}

//
.card {
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  background-color: #fff;
  transition: 0.3s;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 10px;
}
</style>